<script>
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
// import Skeleton from "@/view/front/components/skeleton";
import moment from "moment";
import Swal from "sweetalert2";

/**
 * page-jobs-new component
 */
export default {
  data() {
    return {
      auth: localStorage.getItem("id_token") !== null ? true : false,
      token: `Bearer ${localStorage.getItem("token")}`,
      user:
        localStorage.getItem("user") !== null
          ? JSON.parse(localStorage.getItem("user"))
          : {},
      profile:
        localStorage.getItem("profile") !== null
          ? JSON.parse(localStorage.getItem("profile"))
          : {},
      loading: true,
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,
      keywords: "",
      email: "",
      location: "",
      data: [],
      experiences: [],
      selected_job_experience: [],
      success: false,
    };
  },
  components: {
    Navbar,

    Switcher,
    // Skeleton,
    Footer,
  },
  mounted() {
    this.email = this.auth ? this.user.email : "";
    this.location = this.auth ? this.profile.city : "";
    this.getJobCareerLevel();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    getJobCareerLevel() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-job-career-levels`).then((response) => {
        this.experiences = response.data;
      });
    },
    submitJobAlert() {
      this.success = true;
      if (!this.keywords) {
        Swal.fire({
          title: "",
          text: "Please enter keyword!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      if (
        !this.selected_job_experience ||
        this.selected_job_experience == null ||
        this.selected_job_experience == ""
      ) {
        Swal.fire({
          title: "",
          text: "Please select job experience!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      if (!this.location) {
        Swal.fire({
          title: "",
          text: "Please select job location!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      axios
        .post(`${API_URL}/job-alert`, {
          keywords: this.keywords,
          location: this.location,
          career_level: this.selected_job_experience,
          email: this.email,
        })
        .then((res) => {
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 3000);
          if (res.data === "200") {
            Swal.fire({
              title: "",
              text: "Job alert has been saved.",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          } else {
            Swal.fire({
              title: "",
              text: "Some thing went rong",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          }
        });
    },
    _jobCareerSelection(event) {
      if (event.target.checked) {
        if (!this.selected_job_experience.includes(event.target.value)) {
          var temp = this.selected_job_experience;
          temp.push(event.target.value);
          this.selected_job_experience = temp;
        }
      } else {
        var temp = [];
        this.selected_job_experience.forEach((element) => {
          if (element !== event.target.value) {
            temp.push(event.target.value);
          }
        });
        this.selected_job_experience = temp;
      }
    },
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="section_ch_p bg-light d-table w-100 mt-10">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-8 pagech1">
            <div class="blog_header pl-0">
              <div class="col-lg-12 p-0 w-100">
                <div class="blog_back">
                  <i class="fas fa-long-arrow-alt-left p-1"></i>
                  <router-link to="/career-resources"
                    >Back to blog list</router-link
                  >
                </div>
              </div>
              <h2 class="m_title">CV advice</h2>
              <span class="m_title">by: job4u.pk (3 min read)</span>
            </div>

            <div class="p_about2">
              A CV, or Curriculum Vitae, is arguably the most important document
              in your professional life. This is a snapshot of all you have
              accomplished, your major milestones, your skills, and the entirety
              of your educational and professional experience up until this
              point. Referred to as the Holy Grail of professional life by some,
              the CV makes an indelible first impression, and a good CV goes a
              long way towards a good first impression. So, what exactly should
              your CV show, and how should it do so?
            </div>

            <div class="p_about2">
              As we’ve mentioned above, the CV is a snapshot of your
              professional life, and is the first impression you make on any
              potential employers. Thus, your CV should be professional, eye
              catching, and interesting. Making sure there are no grammatical
              mistakes is of critical importance, and picking a unique,
              minimalistic design can help you stand out amongst the sea of
              applicants. Unique helps you be noticed, while minimalistic allows
              you to maximize available space without looking cramped. Employers
              spend an average of 8 seconds per CV before putting it in the
              accepted or rejected pile, so keeping it short, eye catching, and
              to the point is key.
            </div>

            <div class="p_about2">
              Keep your CV up to date with your current experience, and try to
              tailor it to the jobs you’re applying for; add the skills they’re
              looking for, cut out the redundant information, and put in the
              little bit of time to show that you’re the perfect client for the
              job. Your effort will be appreciated, trust us!
            </div>

            <div class="p_about2">
              It seems easy to leave gaps where you may not have been formally
              employed or pursuing an education, but recurrent gaps in the CV
              can make employers look at you unfavorably. Use that empty space
              to mention volunteer work, skill development, courses, seminars,
              or similar experiences, so that your CV shows you as someone with
              diversified skills and adaptability, rather than showcasing a
              potentially unreliable employee with unexplained gaps in their
              professional path.
            </div>

            <div class="p_about2">
              We’ve mentioned this earlier, but we really cannot stress this
              enough: make sure there are no mistakes in your CV. This is
              perhaps the most critically important point, because potential
              employers will notice mistakes and errors, and this will reflect
              negatively on you. An employer is viewing your CV as an
              introduction to you, and flaws help them to deem you unfit for
              their company. Mistakes show either a poor grasp on the language,
              or carelessness, and both traits are generally considered very
              negative in employers’ eyes. Read and re-read it yourself to be
              sure, and ask friends, family, or other close contacts with a good
              grasp on language and syntax to proofread your CV to be sure.
            </div>

            <div class="p_about2">
              In the digital age, one new concept is that of keywords and search
              engine optimization. If you’re uploading your CV to a jobsite so
              that recruiters can find it when looking to fill a good fit role,
              you’ll want to pack it full of keywords and search engine
              optimized terms. This makes a search engine more likely to pick
              your CV out from the pile, which increases your chances to get
              employed.
            </div>

            <div class="p_about2">
              If you use these tips, creating a unique, effective CV that stands
              out and paints you in the best light will be no problem, and
              you’ll find doors to employment opening to you more and more
              readily. We wish you the best of luck!
            </div>
            <br />
            <!-- <hr> -->
          </div>
          <div class="col-lg-4 col-md-4 mt-3">
            <div class="card">
              <div class="back_overlay">
                <div class="row">
                  <div class="col-lg-6 col-10">
                    <h2 class="">want a great job taillard to your skills ?</h2>
                    <p class="">
                      save time and find higher quality jobs by signing up to
                      job4u.pk
                    </p>
                    <button type="submit" class="btn btn-default join_btn mb-6">
                      <a href="/job-seeker-register">Join job4u.pk now! </a>
                    </button>
                  </div>
                  <div class="col-lg-6 col-2 cv_image_bg">
                    <!-- <img src="/images/carer_resorses/CV.png" alt=""> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="card mt-5">
              <div class="card-headerchch">Create a job alert!</div>
              <div class="card-body p-5">
                <form>
                  <div class="form-group">
                    <label class="labal_style" for="kyeword"
                      >keywords <span style="color: red">*</span></label
                    >
                    <input
                      type="text"
                      v-model="keywords"
                      class="form-control form-control-sm serach_i_ch"
                      id="kyeword"
                      placeholder="Enter Kyewords"
                    />
                  </div>

                  <div class="m_title pt-1 labal_style">
                    CAREER LEVELS <span style="color: red">*</span>
                  </div>
                  <div class="" style="">
                    <ul class="mt-2">
                      <li v-for="(item, index) in experiences" :key="index">
                        <input
                          type="checkbox"
                          :id="item.career_level"
                          :name="item.career_level"
                          class="custom-control-input"
                          :value="item.career_level"
                          v-on:click="_jobCareerSelection($event)"
                        /><label
                          class="custom-control-label m_title"
                          :for="item.career_level"
                          style="font-size: 13px"
                          >{{ item.career_level }}</label
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="form-group">
                    <label class="labal_style" for="exampleInputlocalisation1"
                      >Job Location</label
                    >
                    <input
                      type="text"
                      v-model="location"
                      class="form-control form-control-sm serach_i_ch"
                      id="exampleInputlocalisation1"
                      placeholder="localisation"
                    />
                  </div>
                  <div class="form-group">
                    <label class="labal_style" for="exampleInputYouremail1"
                      >Your email<span style="color: red">*</span></label
                    >
                    <input
                      type="text"
                      v-model="email"
                      class="form-control form-control-sm serach_i_ch"
                      id="exampleInputYouremail1"
                      placeholder="Youremail"
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      class="card-headerch_btn bg_blue_ch"
                      @click="submitJobAlert()"
                      :class="
                        success ? 'spinner spinner-light pinner-right' : ''
                      "
                    >
                      Create job alert!
                    </button>
                    <!-- <div
                      v-if="success"
                      class="alert ml-5"
                      style="
                        background-color: #d4edda;
                        border-color: #d4edda;
                        color: #155724;
                      "
                      role="alert"
                    >
                      Form Submited
                    </div> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
